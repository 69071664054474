










































































import Vue from 'vue';
import { mapMutations } from 'vuex';
import zuersZoneModule from '@/store/modules/zuersZone';
import ZuersZone from '@/components/form/zuers-zone.vue';

export default Vue.extend({
  components: {
    ZuersZone,
  },
  props: {
    versicherungsortItem: {
      type: Object as () => {
        isSelected: boolean;
        num: number;
        Strabe: string;
        PLZ: string;
        Ort: string;
        Land: string;
        Hausnummer: string;
        ZursZone: number;
        Starkregenzone: number;
        Naturgefahren2: string;
        Naturgefahren3: string;
        geltungsbereich: string;
        individualAgreements: string;
      },
      required: true,
    },
    index: Number,
    namespace: {
      type: String,
      default: 'zuersZoneInstance',
    },
  },
  data() {
    return {
      moduleNamespace: `${this.namespace}${this.index + 1}`,
      dataFilled: false,
    };
  },
  methods: {
    ...mapMutations(['SET_PLACE', 'SET_POSTAL_CODE', 'SET_STREET', 'SET_HOUSE_NUMBER', 'SET_ZURS', 'RESET_STATE']),
    setZuersAddressToStore() {
      this.$store.commit(`${this.moduleNamespace}/RESET_STATE`);
      this.$store.commit(`${this.moduleNamespace}/SET_PLACE`, this.versicherungsortItem.Ort);
      this.$store.commit(`${this.moduleNamespace}/SET_POSTAL_CODE`, this.versicherungsortItem.PLZ);
      this.$store.commit(`${this.moduleNamespace}/SET_STREET`, this.versicherungsortItem.Strabe);
      this.$store.commit(`${this.moduleNamespace}/SET_HOUSE_NUMBER`, this.versicherungsortItem.Hausnummer ?? '');
      this.$store.commit(`${this.moduleNamespace}/SET_ZURS`, this.versicherungsortItem.ZursZone);
      this.dataFilled = true;
    },
    updateStreet(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_STREET`, value);
    },
    updatePostalCode(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_POSTAL_CODE`, value);
    },
    updatePlace(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_PLACE`, value);
    },
    updateHouseNumber(value: string) {
      this.$store.commit(`${this.moduleNamespace}/SET_HOUSE_NUMBER`, value);
    },
  },
  watch: {
    versicherungsortItem: {
      handler(newVal) {
        if (!this.dataFilled) {
          this.setZuersAddressToStore();
        }
      },
      deep: true,
    },
  },
  created() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (!store._modulesNamespaceMap[`${this.moduleNamespace}/`]) {
      store.registerModule(this.moduleNamespace, {
        ...zuersZoneModule, namespaced: true,
      });
    }

    this.$watch(
      () => this.$store.getters[`${this.moduleNamespace}/getZurs`],
      (newVal) => {
        if (newVal !== undefined) {
          this.versicherungsortItem.ZursZone = newVal;
          this.versicherungsortItem.Starkregenzone = newVal;
        }
      },
    );
  },
  beforeDestroy() {
    const store = this.$store as any;
    // eslint-disable-next-line no-underscore-dangle
    if (store._modulesNamespaceMap[`${this.moduleNamespace}/`]) {
      store.unregisterModule(this.moduleNamespace);
    }
  },
});
